import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    // "credentials": 'include',
    // "Acess-Control-Allow-Origin":`${server}`
  },
  withCredentials: true,
};
const intialValue = {
  subscriptions: [],
  subscriptionsStatus: 'idle',
  subscription: {},
  state: 'idle',
  error: null,
}

export const getAllSubscriptions = createAsyncThunk(
  'search/getAllSubscriptions',
  async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/subscription/get-all-plans`, axiosConfig);
      return res?.data;
    } catch (e) {
      console.log('e', e)
      return e?.response
    }
  }
)

export const getSubscription = createAsyncThunk(
  'search/getSubscription',
  async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/subscription/fetch-subscription`, axiosConfig);
      return res?.data;
    } catch (e) {
      console.log('e', e)
      return e?.response
    }
  }
)

export const buySubscription = createAsyncThunk(
  'search/buySubscription',
  async (values) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/subscription/user-subscription-intiate`, values, axiosConfig);
      return res;
    } catch (e) {
      console.log('e', e)
      return e?.response
    }
  }
)

export const cancelSubscription = createAsyncThunk(
  'subscription/cancelSubscription',
  async (id) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URI}/subscription/cancel-subscription/${id}`, axiosConfig);
      return res;
    } catch (e) {
      return e.response?.data?.message
    }
  }
)

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: intialValue,
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptions.pending, (state) => {
        state.subscriptionsStatus = 'pending';
        state.error = null;
      })
      .addCase(getAllSubscriptions.fulfilled, (state, action) => {
        state.subscriptionsStatus = 'fulfilled';
        state.subscriptions = action.payload?.data;
        state.error = null;
      })
      .addCase(getAllSubscriptions.rejected, (state, action) => {
        state.subscriptionsStatus = 'rejected';
        state.error = action.payload;
      })
      .addCase(getSubscription.pending, (state) => {
        state.state = 'pending';
        state.error = null;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.state = 'fulfilled';
        state.subscription = action.payload?.data;
        state.error = null;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.state = 'rejected';
        state.error = action.payload;
      })
  }
})

export default subscriptionSlice.reducer;