import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { VENUES } from "../../constants/data";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    // "credentials": 'include',
    // "Acess-Control-Allow-Origin":`${server}`
  },
  withCredentials: true,
};
const intialValue = {
  searchListings: [],
  total: null,
  page: null,
  status: 'idle',
  error: null,
}

export const getSeachListings = createAsyncThunk(
  'search/listings',
  async (params) => {
    const { tab, queryParams = {} } = params;
    const { name, tag, lat, lng, startDate, endDate, min_capacity, max_capacity, min_length, max_length, min_width, max_width, min_height, max_height, genre, searchText, page = 1 } = queryParams;
    try {
      if (tab === VENUES) {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/listings/search/${tab}?searchText=${name || ""}&lat=${lat || ""}&lng=${lng || ""}&start=${startDate || ""}&end=${endDate || ""}&min_capacity=${min_capacity || ""}&max_capacity=${max_capacity || ""}&tag=${tag || ""}&min_length=${min_length || ""}&max_length=${max_length || ""}&min_height=${min_height || ""}&max_height=${max_height || ""}&min_width=${min_width || ""}&max_width=${max_width || ""}&page=${page}`, axiosConfig);
        return res?.data?.data;
      } else {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/listings/search/${tab}?start=${startDate || ""}&end=${endDate || ""}&searchText=${searchText || ""}&genre=${genre || ""}&lat=${lat || ""}&lng=${lng || ""}&page=${page}`, axiosConfig);
        return res?.data?.data;
      }
    } catch (e) {
      console.log('e', e)
      return e?.response
    }
  }
)

const searchSlice = createSlice({
  name: "search",
  initialState: intialValue,
  extraReducers: (builder) => {
    builder
      .addCase(getSeachListings.pending, (state) => {
        state.status = 'pending';
        state.error = null;
      })
      .addCase(getSeachListings.fulfilled, (state, action) => {
        state.searchListings = action.payload?.data;
        state.total = action.payload?.total;
        state.page = action.payload?.page;
        state.status = 'fulfilled';
        state.error = null;
      })
      .addCase(getSeachListings.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
      })
  }
})

export default searchSlice.reducer;