import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { authLogoutUser } from "../../redux/reducers/authSlice";
import { purgeData } from "../../redux/store";
import { ADMIN, AUTHOR, AUTH_SIGNUP, MAX_MOBILE_SCREEN_WIDTH, VENUE_MANAGER, draftId, draftSlug } from "../../constants/data";
import { ensureUser } from "../../util/data";
import VerifyModal from "../VerifyModal/VerifyModal";
import Logo from "../Logo/Logo";
import imgUrl from "../../assets/header_logo.png";
import IconCollection from "../IconCollection/IconCollection";
import css from "./Header.module.scss";
import Modal from "react-modal";
import FormatText from "../FormatText/FormatText";
// import Container from "../Container/Container";




const Header = (props) => {
  const location = useLocation()

  const { user, logoutCurrUser } = props;
  const dispatch = useDispatch();

  const { authName } = useParams() ?? {};
  const isSignupPage = authName === AUTH_SIGNUP;

  const navigate = useNavigate();
  const ensuredUser = ensureUser(user);

  const { id: userId = null, email = null, firstname = null, isverified = false, usertype = null } = ensuredUser || {};
  const [openVerifyModal, setOpenVerifyModal] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    userId && isverified ? setOpenVerifyModal(false) : setOpenVerifyModal(true);
  }, [userId, dispatch, isverified]);

  const handleClickLogout = async (e) => {
    try {
      await logoutCurrUser();
      await purgeData();
      navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  const handleAdminRoute = (e) => {
    return navigate('/admin/details');
  }

  const handleCreateListing = (e) => {
    if (user?.usertype !== VENUE_MANAGER)
      navigate(`/listing/details/${draftId}/${draftSlug}`)
    else
      navigate(`/listing/venue-details/${draftId}/${draftSlug}`)
  };

  const handleShowListings = (e) => {
    navigate('/show-listings')
  };

  const [menuContent, setMenuContent] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuContent(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const isMobile = typeof window !== "undefined" && window.innerWidth < MAX_MOBILE_SCREEN_WIDTH;
  const customStyles = {
    content: {
      top: "0%",
      left: "0%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",

      width: isMobile ? "100%" : "100%",
      // transform: "translate(-50%, -50%)",
      padding: isMobile ? "48px 0px" : "96px 64px 96px",
      maxWidth: isMobile ? "100%" : "480px",
      height: "100vh",
    },
  };
  return (
    <div className={css.headerMain}>
      <div className={css.container}>
        <div className={css.headerWrapper}>
          <span className={css.mobileHumberger} onClick={openModal}> <IconCollection name="MOBILE_MENU" /></span>
          <Link className={css.mainLogo} to="/">
            <Logo src={imgUrl} alt="Header Logo" height="90px" width={"90px"} />
          </Link>
          <div className={css.menuButtons}>
            <Link to="/venues/s">{FormatText('LandingPage.SearchVenuesTitle')}</Link>
            <Link to="/shows/s">{FormatText('LandingPage.SearchShowTitle')}</Link>
            <Link onClick={handleCreateListing}>{FormatText('LandingPage.CreateYourOwnTitle')}</Link>
          </div>
          <div className={css.rightMenus}>
            {/* <SignupButton /> */}
            <div
              onClick={() => setMenuContent(prev => !prev)}
              className={css.userMenuWrapper}
            >
              {user && user?.profileimage ? <img className={css.profileImage} src={user.profileimage?.url} alt="" /> :
                <IconCollection name="USER_ICON" />}
              {menuContent && (
                <div ref={menuRef} className={css.userMenuContents}>
                  {!user ? (
                    <>
                      <Link to="/auth/signup">Sign up</Link>
                      <Link className={css.loginButton} to="/auth/login">Log in</Link>
                    </>
                  ) : (
                    <div>
                      {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/inbox/author")) ? css.activeLink : null} onClick={() => navigate(`/inbox/${AUTHOR}/requests`)}>{FormatText('Header.DropdownInboxpage')}</button>}
                      {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/update-profile")) ? css.activeLink : null} onClick={() => navigate('/update-profile')}>{FormatText('Header.DropdownMyProfilePage')}</button>}
                      {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/show-listings")) ? css.activeLink : null} onClick={handleShowListings}>{FormatText('Header.DropdownShowListing')}</button>}
                      {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/listing")) ? css.activeLink : null} onClick={handleCreateListing}>{FormatText('Header.DropdownCreateListing')}</button>}
                      {usertype === ADMIN && <button className={!!(location?.pathname.includes("/admin")) ? css.activeLink : null} onClick={handleAdminRoute}>{FormatText('Header.DropdownAdminPanel')}</button>}
                      {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/subscription")) ? css.activeLink : null} onClick={() => navigate('/subscription')}>{FormatText('Header.DropdownSubscription')}</button>}
                      <button className={css.logoutButton} onClick={handleClickLogout}>Logout</button>
                    </div>
                  )}
                  <div className={css.topArrowContent}>
                    <IconCollection name="ARROW_TOP" />
                  </div>
                </div>
              )}
            </div>
          </div>
          {ensureUser && userId && !isverified ? <VerifyModal closeModal={openVerifyModal} isSignupPage={isSignupPage} email={email} name={firstname} /> : null}
          <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            className={css.allImageModal}
          >
            <span className={css.closeModal} onClick={closeModal}><IconCollection name="CLOSE_ICON" /></span>
            <div className={css.mobileMenuContents}>
              {!user ? (
                <>
                  <Link className={!!(location?.pathname.includes("/auth/signup")) ? css.activeLink : null} to="/auth/signup">Sign up</Link>
                  <Link className={!!(location?.pathname.includes("/auth/login")) ? css.activeLink : null} to="/auth/login">Log in</Link>
                  <div className={css.menuButtonsMobile}>
                    <button className={!!(location?.pathname.includes("/venues/s")) ? css.activeLink : null} onClick={() => navigate('/venues/s')}>{FormatText('LandingPage.SearchVenuesTitle')}</button>
                    <button className={!!(location?.pathname.includes("/shows/s")) ? css.activeLink : null} onClick={() => navigate('/shows/s')}>{FormatText('LandingPage.SearchShowTitle')}</button>
                  </div>
                </>
              ) :
                <>
                  {user && user?.profileimage ? <img className={css.profileImage} src={user.profileimage?.url} alt="" /> :
                    <IconCollection name="USER_ICON" />}
                  <span className={css.greetingName}>Hello {user?.firstname}</ span>
                  <button className={css.logoutButton} onClick={handleClickLogout}>Logout</button>
                  <button className={!!(location?.pathname.includes("/venues/s")) ? css.activeLink : null} onClick={() => navigate('/venues/s')}>{FormatText('LandingPage.SearchVenuesTitle')}</button>
                  <button className={!!(location?.pathname.includes("/shows/s")) ? css.activeLink : null} onClick={() => navigate('/shows/s')}>{FormatText('LandingPage.SearchShowTitle')}</button>
                  {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/inbox/author")) ? css.activeLink : null} onClick={() => navigate(`/inbox/${AUTHOR}/requests`)}>{FormatText('Header.DropdownInboxpage')}</button>}
                  {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/update-profile")) ? css.activeLink : null} onClick={() => navigate('/update-profile')}>{FormatText('Header.DropdownMyProfilePage')}</button>}
                  {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/show-listings")) ? css.activeLink : null} onClick={handleShowListings}>{FormatText('Header.DropdownShowListing')}</button>}
                  {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/listing")) ? css.activeLink : null} onClick={handleCreateListing}>{FormatText('Header.DropdownCreateListing')}</button>}
                  {usertype === ADMIN && <button className={!!(location?.pathname.includes("/admin")) ? css.activeLink : null} onClick={handleAdminRoute}>{FormatText('Header.DropdownAdminPanel')}</button>}
                  {usertype !== ADMIN && <button className={!!(location?.pathname.includes("/subscription")) ? css.activeLink : null} onClick={() => navigate('/subscription')}>{FormatText('Header.DropdownSubscription')}</button>}
                </>}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state.user;
  return {
    user,
  }
};

const mapDispatchToProps = dispatch => ({
  logoutCurrUser: () => dispatch(authLogoutUser()),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);
